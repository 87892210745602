// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI. api.regisafe-online.de

// http://gws1.cctx.de:81/ - intern
// https://regisafe.cctx.de/ - extern
// \\gws1.cctx.de\web - UNC Pfad - Webserver
// \\w2022-regisafe.cctx.de\regisafe - UNC Pfad - IQ Server

// export const IQAPI_DOMAIN = 'https://regisafe-prod.cctx.de/';
// export const IQAPI_ENDPOINT = 'https://regisafe-prod.cctx.de/iqAPI';

// export const IQAPI_DOMAIN = 'https://regisafe.cctx.de/';
// export const IQAPI_ENDPOINT = 'https://regisafe.cctx.de/iqAPI';

export const IQAPI_HTTP_REQUEST_TIMEOUT = 25000;
export const IQAPI_CONFIGURATION_FILE = 'configuration.develop.rsapp.json';

export const IQAPI_ROUTES = {
  login: '/Session.Login',
  logout: '/Session.Logout',
  conditions: '/OrgaManager.GetConditions',
  getList: '/OrgaManager.GetList'
};

export const IQAPI_MIN_VERSION_MAJOR = 4;
export const IQAPI_MIN_VERSION_MINOR = 12;

export const APP_ROUTES = {
  anmeldung: '/regisafe/anmeldung',
  suche: '/regisafe/suche',
  suchergebnisse: '/regisafe/suchergebnisse',
  detail: '/regisafe/detail',
  barcode: '/regisafe/barcode',
  suchegespeichert: '/regisafe/suche-gespeichert',
  inbearbeitung: '/regisafe/in-bearbeitung',
};

export const APP_STORAGE = {
  name: '__regisafe',
  tokenname: 'sessionToken',
  conditions: 'conditions',
  searchforms: 'searchforms',
  sortingdata: 'sortingdata',
  searchmodedata: 'searchmodedata',
  sortingdataselection: 'sortingdataselection',
  endpoint: 'endpoint',
  localfavorites: 'localfavorites',
  savedSearches: 'savedSearches',
  appConfig: 'appConfig',
  recivedintend: 'recivedintend',
  recivedintendfromcustomurlscheme: 'recivedintendfromcustomurlscheme',
  user: {
    username: 'userName',
    selectedstructure: 'selectedstructure',
    settings: 'settings',
    sortings: 'sortings',
    edit: 'schriftgut',
    locked: 'locked',
    offlinelogin: 'offlinelogin',
    biometriclogin: 'biometriclogin',
    opendocsindefaultviewer: 'opendocsindefaultviewer'
  }
};

export const APP_MESSAGES = {
  globalDataChanged: 'globaldata:changed',
};

export const APP_ERROR_MESSAGES = {
  noResults: 'Keine Suchergebnisse gefunden.',
};

export const APP_CONDITION_ITEMS = {
  appStructs: 'appStructs',
  orgaStructs: 'orgaStructs',
  dataStores: 'dataStores'
};

export const DIRECTORY_TREE = {
  root: 'regisafe',
  subfolders: {
    edit: 'edit',
    add: 'add'
  },
  imagefileext: '.jpg'
};

export const CACHE_DOWNLOAD_DIRECTORY = 'regisafe_downloads';
export const APP_EDIT_FOLDER = 'regisafe/bearbeiten/';
export const APP_ASSIGN_FOLDER = 'regisafe/zuordnen/';
export const APP_CACHE_FOLDER = 'regisafe/cache/';

export const APP_ICON_PATH = 'assets/icons/regisafe/';
