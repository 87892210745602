import {NgModule} from '@angular/core';
import {UnderConstructionDirective} from './directives/under-construction.directive';
import {NavCompensationSafeAreaDirective, NavPositionDirective} from './directives/nav-position.directive';
import {ContentPositionDirective} from './directives/content-position.directive';
import {IonicModule} from '@ionic/angular';
import {DateienInBearbeitungDirective} from './directives/dateien-in-bearbeitung.directive';
import {ToggleFooterDirective, ToggleSidebarDirective, ToggleSearchFabDirective} from './directives/toggle-footer.directive';
import {NativeOnlyDirective, NetworkStatusDirective} from './directives/network-status.directive';

@NgModule({
	declarations: [
		UnderConstructionDirective,
		NavPositionDirective,
		ContentPositionDirective,
		DateienInBearbeitungDirective,
		ToggleFooterDirective,
		ToggleSidebarDirective,
		ToggleSearchFabDirective,
		NetworkStatusDirective,
		NativeOnlyDirective,
		NavCompensationSafeAreaDirective
	],
	imports: [
		IonicModule
	],
	exports: [
		UnderConstructionDirective,
		NavPositionDirective,
		ContentPositionDirective,
		DateienInBearbeitungDirective,
		ToggleFooterDirective,
		ToggleSidebarDirective,
		ToggleSearchFabDirective,
		NetworkStatusDirective,
		NativeOnlyDirective,
		NavCompensationSafeAreaDirective
	]
})
export class RegisafeDirectiveModule {
}
