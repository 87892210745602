import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Capacitor} from '@capacitor/core';

@Directive({
	selector: '[appNetworkStatus]'
})
export class NetworkStatusDirective {

	networkStatus = false;
	networkStatus$: Subscription = Subscription.EMPTY;

	constructor(el?: ElementRef, private renderer?: Renderer2) {
		this.networkStatus = navigator.onLine;

		this.networkStatus$ = merge(
			of(null),
			fromEvent(window, 'online'),
			fromEvent(window, 'offline')
		)
			.pipe(map(() => navigator.onLine))
			.subscribe(online => {
				this.networkStatus = online;
				switch (el.nativeElement.nodeName) {
					case 'BUTTON':
						if (online) {
							this.renderer.removeAttribute(el.nativeElement, 'disabled');
						} else {
							this.renderer.setAttribute(el.nativeElement, 'disabled', 'true');
						}
						break;
					case 'ION-BUTTON':
						if (online) {
							this.renderer.removeAttribute(el.nativeElement, 'disabled');
						} else {
							this.renderer.setAttribute(el.nativeElement, 'disabled', 'true');
						}
						break;
					case 'ION-TAB-BUTTON':
						if (online) {
							this.renderer.removeAttribute(el.nativeElement, 'disabled');
						} else {
							this.renderer.setAttribute(el.nativeElement, 'disabled', 'true');
						}
						break;
					case 'APP-REGISAFE-OFFLINE-LOGIN':
						if (online) {
							this.renderer.setStyle(el.nativeElement, 'display', 'none');
						} else {
							this.renderer.removeStyle(el.nativeElement, 'display');
						}
						break;
				}

			});

	}

}

@Directive({
	selector: '[appNativeOnly]'
})
export class NativeOnlyDirective {
	constructor(el?: ElementRef, private renderer?: Renderer2) {
		if (Capacitor.getPlatform() === 'web') {
			this.renderer.setStyle(el.nativeElement, 'display', 'none');
		}
	}
}
