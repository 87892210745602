import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iconClass',
})
export class IconClassPipe implements PipeTransform {
  
  transform(value: string, args?: any): any {
    
    let output = '';
    
    switch (value.toLowerCase()) {
      case 'aktenplanverwaltung':
        output = 'folder';
        break;
      case 'vorgangsverwaltung':
        output = 'folder-open';
        break;
      case 'schriftgutverwaltung':
        output = 'file-alt';
        break;
      case 'adressverwaltung':
        output = 'address-card';
        break;
      case 'sitzungsverwaltung':
        output = 'users';
        break;
      case 'global':
        output = 'globe';
        break;
    }
    
    return output;
  }
}
