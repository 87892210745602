import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MessagesService {

	private subject = new Subject<any>();

	constructor() {
	}

	sendMessage(msg: any) {
		this.subject.next({message: msg});
	}

	clearMessages() {
		this.subject.next();
	}

	getMessages(): Observable<any> {
		return this.subject.asObservable();
	}
}
