import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppConfig} from './app.config';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ImagePicker} from '@awesome-cordova-plugins/image-picker/ngx';
import {WebIntent} from '@awesome-cordova-plugins/web-intent/ngx';
import {FilePath} from '@awesome-cordova-plugins/file-path/ngx';
import {HttpClientModule} from '@angular/common/http';
import {FileTransfer} from '@awesome-cordova-plugins/file-transfer/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {NativeStorage} from '@awesome-cordova-plugins/native-storage/ngx';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {IonicStorageModule} from '@ionic/storage';
import {APP_STORAGE} from '../environments/environment';

import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

import {TranslatePipe} from './provider/pipes/translate.pipe';
import {APP_BASE_HREF, DatePipe} from '@angular/common';
import {IconClassPipe} from './provider/pipes/icon-class.pipe';

import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {RegisafeDirectiveModule} from './directives.module';
import {Chooser} from '@awesome-cordova-plugins/chooser/ngx';
import {LaunchNavigator} from '@awesome-cordova-plugins/launch-navigator/ngx';

@NgModule({
	declarations: [AppComponent, TranslatePipe, IconClassPipe],
	entryComponents: [],
	imports: [BrowserModule,
		IonicModule.forRoot({
			animated: false,
			mode: 'md',
			rippleEffect: false
		}),
		IonicStorageModule.forRoot({
			name: APP_STORAGE.name,
			driverOrder: ['indexeddb']
		}),
		AppRoutingModule,
		HttpClientModule,
		FontAwesomeModule,
		RegisafeDirectiveModule],
	providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
		ImagePicker,
		Chooser,
		WebIntent,
		FilePath,
		File,
		FileTransfer,
		FileOpener,
		NativeStorage,
		TranslatePipe,
		DatePipe,
		IconClassPipe,
		AppConfig,
		Camera,
		LaunchNavigator,
		AndroidPermissions,
		{
			provide: APP_INITIALIZER,
			useFactory: (config: AppConfig) => () => config.load(),
			deps: [AppConfig],
			multi: true
		},
		{
			provide: APP_BASE_HREF,
			useValue: window['base-url']
		}],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(far, fas, fab);
	}
}
