import {Injectable} from '@angular/core';
import {ConfigurationObject, SearchResultCache} from '../../interfaces/interfaces.regisafe';
import {AppConfig} from '../../app.config';

@Injectable({
	providedIn: 'root'
})
export class SessionDataService {

	public configuration: ConfigurationObject;

	searchString = '';
	dataStores: Array<any> = [];
	filterData: object = null;
	jsonBody: any;
	searchResultCache: SearchResultCache = {
		stateURL: '',
		data: {}
	};
	iqoArray: Array<any> = [];

	constructor(public appConfig: AppConfig) {
		this.configuration = this.appConfig.getConfig();
	}
}
