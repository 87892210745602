import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    
    transform(value: any, ...args: any[]): any {
        
        let output: string;
        
        switch (value) {
            case 'Aktenplanverwaltung':
                output = 'Structure';
                break;
            case 'VorgangsVerwaltung':
                output = 'Folder';
                break;
            case 'Vorgangsverwaltung':
                output = 'Folder';
                break;
            case 'Schriftgutverwaltung':
                output = 'Document';
                break;
            case 'Sitzungsverwaltung':
                output = 'Meeting';
                break;
            case 'Adressverwaltung':
                output = 'Address';
                break;
        }
        
        return output;
    }
    
}

@Pipe({
    name: 'subStr'
})
export class SubstrPipe implements PipeTransform {
    
    transform(value: string, args?: number): any {
      
        let output: string;
        let end: string;
        
        try {
            if (value.length > args) {
                end = ' ...';
            }
            output = value.substr(0, args) + end;
            return output;
        } catch (e) {
            return 'Bezeichner konnte nicht gelesen werden. (subStr: ' + value + ')';
        }
        
    }
    
}
