import {Directive, ElementRef, Renderer2} from '@angular/core';
import {element} from 'protractor';

@Directive({
	selector: '[appNavPosition]'
})
export class NavPositionDirective {

	constructor(el?: ElementRef, private renderer?: Renderer2) {
		const navPosition = localStorage.getItem('appNavPosition');
		if (navPosition && (navPosition === 'left' || navPosition === 'right' || navPosition === 'bottom')) {
			this.renderer.setAttribute(el.nativeElement, 'class', navPosition);
		} else {
			this.renderer.setAttribute(el.nativeElement, 'class', 'left');
			localStorage.setItem('appNavPosition', 'left');
		}

	}

}

@Directive({
	selector: '[appCompensationSafeArea]'
})
export class NavCompensationSafeAreaDirective {
	constructor(el?: ElementRef, private renderer?: Renderer2) {
		const regisafeSafeAreaTop = getComputedStyle(document.documentElement).getPropertyValue('--regisafeSafeAreaT');
		this.renderer.setStyle(el.nativeElement, 'height', 'calc(100% - (173px + ' + regisafeSafeAreaTop + '))');

		const regisafeSafeAreaBottom = getComputedStyle(document.documentElement).getPropertyValue('--regisafeSafeAreaB');

		if (regisafeSafeAreaBottom !== '0px') {
			this.renderer.setStyle(el.nativeElement, 'padding-bottom', '0px');
			this.renderer.setStyle(el.nativeElement, 'padding-left', '0px');
		}
		
		
	}
}
