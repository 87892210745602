import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
	selector: '[appContentPosition]'
})
export class ContentPositionDirective {

	constructor(el?: ElementRef, private renderer?: Renderer2) {
		const navPosition = localStorage.getItem('appNavPosition');
		if (navPosition && (navPosition === 'left' || navPosition === 'right' || navPosition === 'bottom')) {
			this.renderer.addClass(el.nativeElement, navPosition);
		} else {
			this.renderer.addClass(el.nativeElement,'left');
			localStorage.setItem('appNavPosition', 'left');
		}

	}

}
