import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IQAPI_CONFIGURATION_FILE} from '../environments/environment';
import {Platform} from '@ionic/angular';
import {ConfigurationObject} from './interfaces/interfaces.regisafe';
import {StorageService} from './provider/storage/storage.service';

// https://www.technouz.com/5125/changing-angular-environment-variables-at-runtime/

@Injectable()
export class AppConfig {

	private configuration: ConfigurationObject = null;

	constructor(private http: HttpClient,
				private platform: Platform,
				private storageService: StorageService) {

	}

	public getConfig() {
		return this.configuration;
	}

	public setConfig(configuration: ConfigurationObject) {
		this.configuration = configuration;
	}

	public load() {

		return new Promise((resolve, reject) => {
			this.http.get('./assets/config/' + IQAPI_CONFIGURATION_FILE)
				.subscribe((data: ConfigurationObject) => {

					const existingConfig = this.storageService.getAppConfigurationSync();
					if (!existingConfig) {
						console.log('DefaultConfig.load: ', data);
						this.configuration = data;
					} else {
						console.log('CustomConfig.load: ', existingConfig);
						this.configuration = existingConfig;
					}

					this.platform.ready().then(() => {
						setTimeout(() => {
							resolve(true);
						}, 2000);
					});

				}, (error) => {
					console.log('AppConfig.load error: ', error);
					reject('SET CONFIGUTARION');
				});
		});
	}

	private handleConfigurationData(configuration: object) {

	}
}
