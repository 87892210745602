import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./regisafe/regisafe.module').then(m => m.TabsPageModule)
	},
	{
		path: 'detail',
		loadChildren: () => import('./views/detail/detail.module').then(m => m.DetailPageModule),

	},
	{
		path: 'in-bearbeitung',
		loadChildren: () => import('./views/in-bearbeitung/in-bearbeitung.module').then(m => m.InBearbeitungPageModule)
	},
	{
		path: 'dateien-zuordnen',
		loadChildren: () => import('./views/dateien-zuordnen/dateien-zuordnen.module').then(m => m.DateienZuordnenPageModule)
	},
	{
		path: 'hierarchie',
		loadChildren: () => import('./views/hierarchie/hierarchie.module').then(m => m.HierarchiePageModule)
	},
	{
		path: 'abmeldung',
		loadChildren: () => import('./views/abmeldung/abmeldung.module').then(m => m.AbmeldungPageModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			useHash: true,
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
