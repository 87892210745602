import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, Platform} from '@ionic/angular';
import {Intent, WebIntent} from '@awesome-cordova-plugins/web-intent/ngx';
import {FilePath} from '@awesome-cordova-plugins/file-path/ngx';
import {
	ActivatedRoute,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router
} from '@angular/router';
import {APP_ROUTES, APP_STORAGE, IQAPI_ROUTES} from '../environments/environment';
import {StorageService} from './provider/storage/storage.service';
import {DateiSystemService} from './provider/datei-system/datei-system.service';
import {UserService} from './provider/user/user.service';
import {SendIntent} from 'send-intent';
import {ErrorHandlingService} from './provider/error-handling/error-handling.service';
import {ALERT_TXT, LOADER_TXT, TOAST_TXT} from './strings/language';
import {MessagesService} from './provider/messages/messages.service';
import {SessionDataService} from './provider/session-data/session-data.service';
import {App as CapacitorApp, App, URLOpenListenerEvent} from '@capacitor/app';
import {Keyboard} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';
import {tap} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ConfigurationObject} from './interfaces/interfaces.regisafe';
import {AppConfig} from './app.config';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	public configuration: ConfigurationObject;
	public selectedIndex = 0;
	public appPages = [
		{
			title: 'Suchen',
			url: '/search',
			icon: 'search',
			disable: false
		},
		{
			title: 'Favoriten',
			url: '/favorites',
			icon: 'star',
			disable: false
		},
		{
			title: 'Dateien zuordnen',
			url: '/assign-files',
			icon: 'clipboard',
			disable: false
		},
		{
			title: 'Dateien in Bearbeitung',
			url: '/edit-files',
			icon: 'create',
			disable: true
		},
		{
			title: 'Einstellungen',
			url: '/settings',
			icon: 'settings',
			disable: true
		},
		{
			title: 'Informationen',
			url: '/information',
			icon: 'information-circle',
			disable: false
		},
		{
			title: 'Abmelden',
			url: '/logout',
			icon: 'log-out',
			disable: false
		}
	];

	alert: HTMLIonAlertElement;
	loader: HTMLIonLoadingElement;

	constructor(private platform: Platform,
				private webIntent: WebIntent,
				private filePath: FilePath,
				private router: Router,
				private alertCtrl: AlertController,
				private loadingCtrl: LoadingController,
				private errorHandling: ErrorHandlingService,
				private activatedRoute: ActivatedRoute,
				private storageService: StorageService,
				private userService: UserService,
				private dateiSystem: DateiSystemService,
				private msgService: MessagesService,
				public appConfig: AppConfig,
				private httpClient: HttpClient,
				private sessionData: SessionDataService) {

		this.configuration = this.appConfig.getConfig();

		if (Capacitor.getPlatform() !== 'web') {
			Keyboard.setAccessoryBarVisible({isVisible: true});
		} else {
			console.log('Platform is: ', Capacitor.getPlatform());
		}

		CapacitorApp.addListener('backButton', async ({canGoBack}) => {
			const url = this.router.url;
			if (url === '/regisafe/suche') {
				this.leaveAppAlert();
			} else {
				if (canGoBack) {
					setTimeout(() => {
						window.history.back();
					}, 150);

				} else {
					this.leaveAppAlert();
				}
			}
		});

		this.initializeApp();

		// For MDM!!!
		// https://github.com/oracle/cordova-plugin-emm-app-config

		// Aktuelle Seite in Navigation auf activ (selected) setzen
		// Beim RouterEvent 'NavigationEnd' wird die aktuelle URL ausgelesen
		// und mit dem Array this.appPages verglichen.
		this.router.events.subscribe((event: any) => {

			switch (true) {
				case event instanceof NavigationEnd: {
					const path = this.router.url;
					this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());

					break;
				}

				case event instanceof NavigationStart: {
					break;
				}
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					break;
				}

				default: {
					break;
				}
			}
		});
	}

	async leaveAppAlert() {
		this.alert = await this.alertCtrl.create({
			header: ALERT_TXT.header.str00010,
			subHeader: ALERT_TXT.subHeader.str00150,
			buttons: [{
				text: 'Verlassen',
				handler: () => {
					this.alert.dismiss(true);
					return false;
				}
			}, {
				text: 'Abbrechen',
				role: 'cancel',
				handler: () => {
					this.alert.dismiss(false);
					return false;
				}
			}]
		});

		await this.alert.present();
		await this.alert.onDidDismiss().then((event) => {
			if (event.data === true && event.role !== 'cancel') {
				this.sessionLogoutAndCloseApp();
			} else {
				// Stay here
			}
		});
	}

	async sessionLogoutAndCloseApp() {

		this.loader = await this.loadingCtrl.create({
			message: LOADER_TXT.message.str00010
		});
		await this.loader.present();

		const accountData = {
			apiID: this.configuration.iqapiAppId,
			sessionToken : this.storageService.getToken() || '123'
		};
		const options: object = {headers: {}, responseType: 'json', reportProgress: true, observe: 'response'};
		this.httpClient
			.post(this.configuration.iqapiEndpoint + IQAPI_ROUTES.logout, accountData, options)
			.pipe(tap((res: HttpResponse<any>) => {
				// SessionToken aus Datenbank löschen
				this.storageService.removeToken();
			}))
			.subscribe(async (data: HttpResponse<any>) => {
				await this.loader.dismiss();
				this.loader = null;
				App.exitApp();
			}, async (error) => {
				// Fehlerfall ignorieren, ggf. vorhandenen Token löschen und Weiterleitung zur Anmeldung
				this.storageService.removeToken();
				await this.loader.dismiss();
				this.loader = null;
				App.exitApp();
			});
	}


	async initializeApp() {
		this.platform.ready().then(async () => {

			// Hide the splash (you should do this on app launch)
			// await SplashScreen.hide();

			if (this.platform.is('ios')) {
				// create Folder for "Datei zuordnen"
				// this.fileSystemService.createMainFolderForCapture();
				// create Folder for "Datei bearbeiten"
				// this.fileSystemService.createMainFolderForEdit();
			} else if (this.platform.is('android')) {
				// create Folder for "regisafe" include Subdirectories
				// "Datei zuordnen" and "Datei bearbeiten"
				// this.fileEditingService.checkDirectory(DIRECTORY_TREE.root);
			}

			// set Initial Storage Data
			this.storageService.setInitialStorage(APP_STORAGE.user.edit, {});
			this.storageService.setInitialStorage(APP_STORAGE.user.locked, {});
			this.storageService.setInitialStorage(APP_STORAGE.user.offlinelogin, 0);
			this.storageService.setInitialStorage(APP_STORAGE.user.biometriclogin, 0);
			this.storageService.setInitialStorage(APP_STORAGE.user.opendocsindefaultviewer, 1);

			// do some Stuff here if App becomes from Background to Front
			this.platform.resume.subscribe(async () => {

			});

			if (this.platform.is('android')) {

				this.webIntent.onIntent()
					.subscribe((intentData: Intent | any) => {

						// if (intentData.hasOwnProperty('clipItems')) {
						// 	// console.log('### onIntent - SEND (share) clipItems ###: ', intentData.clipItems[0]);
						// 	// this.filePath.resolveNativePath(intentData.clipItems[0].uri)
						// 	// 	.then(async (uri) => {
						// 	// 		console.log('resolveNativePath aaa: ', uri);
						// 	// 	});
						// }

						if (intentData.hasOwnProperty('data')) {
							if (intentData.data.includes('regisafe://')) {
								console.log('### INTENT - Custom URL Scheme: ', intentData.data);
								this.storageService.setRecivedIntendFromURLScheme(intentData.data);
								this.sessionData.iqoArray = [];
								this.msgService.sendMessage({context: 'intent:receivedfromcustomurlscheme'});
							} else {
								console.log('### INTENT - Send File: ', intentData.data);
								this.filePath.resolveNativePath(intentData.data)
									.then((uri) => {

										const filename = uri.substring(uri.lastIndexOf('/') + 1);
										const path = uri.replace(filename, '');
										const extension = uri.substring(uri.lastIndexOf('.') + 1);

										console.log('### IQO CHECK EXTENSION 111 ###: ', uri, extension.toLowerCase());

										if (uri && extension.toLowerCase() === 'iqo') {
											const intentObj = {
												url: uri,
												type: 'application/octet-stream',
												title: filename

											};
											this.storageService.setRecivedIntend(JSON.stringify(intentObj));
											this.sessionData.iqoArray = [];
											// TODO: prüfen ob Benutzer angemeldet ist
											this.msgService.sendMessage({context: 'intent:received'});
										} else {
											// silence
										}

									})
									.catch((e) => {
										console.log('resolveNativePath error: ', e);
										this.errorHandling.errorMessage(TOAST_TXT.message.str00020, 'toast', true);
									});
							}
						}

					}, (e) => {
						console.log('### onIntent - SEND ### error: ', e);
					});

			} else {
				App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
					console.log('### appUrlOpen ios ###: ', event);
					const uri = event.url;

					const filename = uri.substring(uri.lastIndexOf('/') + 1);
					const path = uri.replace(filename, '');
					const extension = uri.substring(uri.lastIndexOf('.') + 1);
					if (uri && extension.toLowerCase() === 'iqo') {
						const intentObj = {
							url: uri,
							type: 'application/octet-stream',
							title: filename

						};
						this.storageService.setRecivedIntend(JSON.stringify(intentObj));
						this.sessionData.iqoArray = [];
						// TODO: prüfen ob Benutzer angemeldet ist
						this.msgService.sendMessage({context: 'intent:received'});
					} else {
						// silence
					}
				})
			}

			// https://github.com/carsten-klaffke/send-intent
			// nimmt eine Intend (Share) engegen und speichert den Inhalt im localstorage
			SendIntent.checkSendIntentReceived()
				.then((result: any) => {
					if (!result.url) {
						return false;
					}
					const extension = result.url.substring(result.url.lastIndexOf('.') + 1);
					console.log('### IQO CHECK EXTENSION 333 ###: ', result.url, extension.toLowerCase());

					if (result.url && extension.toLowerCase() === 'iqo') {
						this.storageService.setRecivedIntend(JSON.stringify(result));
					} else {
						// silence
					}
				})
				.catch((err) => {
					console.error('SendIntent error', err)
				});
		});
	}

	async ngOnInit() {
		const regisafeOrdner = await this.dateiSystem.pruefeAppOrder('regisafe');
		const bearbeitenOrdner = await this.dateiSystem.pruefeAppOrder('regisafe/bearbeiten');
		const zuordnenOrdner = await this.dateiSystem.pruefeAppOrder('regisafe/zuordnen');
		const cacheOrdner = await this.dateiSystem.pruefeAppOrder('regisafe/cache');
		if (!regisafeOrdner) {
			const regisafeOrdnerErstellen = await this.dateiSystem.erstelleAppOrdner('regisafe');
		}
		if (!bearbeitenOrdner) {
			const bearbeitenOrdnerErstellen = await this.dateiSystem.erstelleAppOrdner('regisafe/bearbeiten');
		}
		if (!zuordnenOrdner) {
			const zuordnenOrdnerErstellen = await this.dateiSystem.erstelleAppOrdner('regisafe/zuordnen');
		}
		if (!cacheOrdner) {
			const cacheOrdnerErstellen = await this.dateiSystem.erstelleAppOrdner('regisafe/cache');
		}
	}


}
