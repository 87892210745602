import {Directive, HostListener, OnInit, ElementRef} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Directive({
    selector: '[appUnderConstruction]'
})
export class UnderConstructionDirective implements OnInit {

    constructor(private toastCtrl: ToastController, private el: ElementRef) {
        el.nativeElement.disabled = true;
    }

    ngOnInit() {
        console.log('### appUnderConstruction calling ###');
    }

    // @HostListener('click') onClick() {
    //     this.presentToast();
    // }
    //
    // async presentToast() {
    //     const toast = await this.toastCtrl.create({
    //         message: '[DEV] Currently not implemented.',
    //         duration: 2000
    //     });
    //     toast.present();
    // }

}
