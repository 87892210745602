import {ErrorHandler, Injectable} from '@angular/core';
import {AlertController, ToastController, ToastOptions} from '@ionic/angular';
import {Router} from '@angular/router';
import {FileError} from '@awesome-cordova-plugins/file';
import {APP_ROUTES} from "../../../environments/environment";
import {ALERT_TXT} from '../../strings/language';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlingService implements ErrorHandler {

	toast: HTMLIonToastElement;
	alert: HTMLIonAlertElement;
	regisafeErrorCodes = {};

	constructor(private toastCtrl: ToastController,
				private alertCtrl: AlertController,
				private router: Router) {
	}

	/**
	 *
	 * @param error - Fehlermeldung
	 * @param origin - Herkunft der Fehlermeldung
	 */
	public handleError(errorResponse: any, origin?: string, objectLock?: boolean): void {
		console.log('handleError: ', errorResponse);
		try {
			const errObj = errorResponse.error;
			// check regisafeError
			if (errObj.hasOwnProperty('_error')) {
				// eslint-disable-next-line no-underscore-dangle
				const errMsg = errObj._error['1'].eMsg;
				// eslint-disable-next-line no-underscore-dangle
				const h1 = errObj._error['1'].hl;
				if (!objectLock) {
					this.errorMessage(errMsg + ' (' + h1 + ')', 'toast');
				} else {

				}
			} else {
				this.errorMessage(errorResponse.message, 'toast');
			}

			switch (errorResponse.status) {
				// 400
				case 401: {
					this.router.navigate([APP_ROUTES.anmeldung]);
					break;
				}
				case 403: {
					this.router.navigate([APP_ROUTES.anmeldung]);
					break;
				}
				// 500
				case 502: {
					this.router.navigate([APP_ROUTES.anmeldung]);
					break;
				}
			}

		} catch (e) {
			console.log('handleError error in TRY: ', e);
			this.errorMessage(errorResponse.message, 'toast');
		}
	}

	public handleConfError(errorResponse: any, origin?: string): boolean {
		console.log('handleConfError: ', errorResponse);
		try {
			const errObj = errorResponse.error;
			if (errObj.hasOwnProperty('_error')) {
				// eslint-disable-next-line no-underscore-dangle
				if (errObj._error.hasOwnProperty('1')) {
					return true;
				}
			} else {
				this.errorMessage('Bitte prüfen Sie Ihre Konfigrationsdatei. Der angegebene Server ist nicht erreichbar.', 'toast');
				return false;
			}

		} catch (e) {
			console.log('handleConfError error in TRY: ', e);
			this.errorMessage('Bitte prüfen Sie Ihre Konfigrationsdatei. Der angegebene Server ist nicht erreichbar.', 'toast');
			return false;
		}
	}

	public handleFileSystemError(fileError: FileError) {
		switch (fileError.message) {
			case 'SECURITY_ERR': {
				// eslint-disable-next-line max-len
				this.errorMessage('Notwendige Ordner konnten nicht angelegt werden. Bitte öffnen Sie die App "Einstellungen" und prüfen Sie ob regisafe den erforderlichen Berechtigungen hat.', 'toast', true);
				break;
			}
			case 'NOT_FOUND_ERR': {
				this.errorMessage('Die Datei konnte nicht gefunden bzw. geöffnet werden!', 'toast', true);
				break;
			}
		}
	}

	public handlePluginError(error: string, plugin: string) {
		const msg = error + ' (' + plugin + ')';
		this.errorMessage(msg, 'toast', true);
	}

	async errorMessage(msg: string, type: string, confirm?: boolean, dur?: number, pos: any = 'bottom') {

		if (!dur) {
			dur = 4000;
		}

		if (type === 'toast') {
			try {
				await this.toast.dismiss();
			} catch (e) {
				console.log('No Toast visible', e);
			}

			const options: ToastOptions = {
				message: msg,
				duration: dur,
				position: pos,
				buttons: []
			};

			if (confirm) {
				options.buttons.push({
					text: 'OK',
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					}
				});
				delete options.duration;
			}

			this.toast = await this.toastCtrl.create(options);
			this.toast.removeAttribute('tabindex');
			await this.toast.present();

		} else if (type === 'alert') {
			try {
				await this.alert.dismiss();
			} catch (e) {
				console.log('No Alert visible', e);
			}

			const alertOptions = {
				header: ALERT_TXT.header.str00010,
				subHeader: msg,
				buttons: ['Ok']
			};

			this.alert = await this.alertCtrl.create(alertOptions);
			await this.alert.present();
		}

	}

}
