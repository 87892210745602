import {Injectable} from '@angular/core';
import {NativeStorage} from '@awesome-cordova-plugins/native-storage/ngx';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {APP_STORAGE, IQAPI_MIN_VERSION_MAJOR, IQAPI_MIN_VERSION_MINOR} from '../../../environments/environment';
import {MessagesService} from '../messages/messages.service';
import {ConfigurationObject, IQAPIVersionObject, SearchObjectsExObj} from '../../interfaces/interfaces.regisafe';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor(private nativeStorage: NativeStorage,
				public platform: Platform,
				private msgService: MessagesService,
				private storage: Storage) {
	}

	// native
	isNativeRun(): boolean {
		let isNative = false;
		if (this.platform.is('ios') ||
			this.platform.is('android')) {
			isNative = true;
		} else {
			isNative = false;
		}

		console.log('isNative: ', isNative);
		return isNative;
	}

	// async getItem(key): Promise<any> {
	// 	if (this.isNativeRun()) {
	// 		return await this.nativeStorage.getItem(key);
	// 	} else {
	// 		return await localStorage.getItem(key);
	// 	}
	// }

	async getItem(key): Promise<any> {
		return this.storage.get(key).then((data) => {
			return data;
		});
	}

	// async setItem(key, value) {
	// 	if (this.isNativeRun()) {
	// 		return await this.nativeStorage.setItem(key, value);
	// 	} else {
	// 		return await localStorage.setItem(key, value);
	// 	}
	// }

	async setItem(key: string, value: any) {
		return this.storage.set(key, value).then((data) => {
			return data;
		});
	}

	async removeItem(key): Promise<any> {
		return await this.nativeStorage.remove(key);
	}

	async getAllKeys(): Promise<void> {

	}

	// ionic storage
	/**
	 * Token wird sowohl im ionic Storage als auch im localStorage abgespeichert (für sync bzw. async Zugriff)
	 * setToken - String
	 *
	 * @param token - Token aus ApiService.login
	 */
	setToken(token: string) {
		this.storage.set(APP_STORAGE.tokenname, token);
		localStorage.setItem(APP_STORAGE.tokenname, token);
	}

	removeToken() {
		this.storage.remove(APP_STORAGE.tokenname);
		localStorage.removeItem(APP_STORAGE.tokenname);
	}

	removeItemFromStorage(item: string) {
		this.storage.remove(item);
	}

	async getTokenFromStorage(): Promise<any> {
		return this.storage.get(APP_STORAGE.tokenname);
	}

	/**
	 * setConditions
	 *
	 * @param conditions - Object
	 */
	setConditions(conditions: object) {
		this.storage.set(APP_STORAGE.conditions, conditions);
		localStorage.setItem(APP_STORAGE.conditions, JSON.stringify(conditions)); // speichern für synchronen Zugriff
	}

	async getConditions(item?: string) {
		if (!item) {
			return this.storage.get(APP_STORAGE.conditions);
		} else {
			const conditions = await this.storage.get(APP_STORAGE.conditions);
			return conditions[item];
		}
	}

	removeConditions() {
		this.storage.remove(APP_STORAGE.conditions);
		localStorage.removeItem(APP_STORAGE.conditions);
	}

	/**
	 * checkUpdateNecessary()
	 *
	 * prüft ob eine Update von regisafe notwendig ist, damit diese Version der
	 * App lauffähig ist
	 *
	 * @param version
	 */
	checkUpdateNecessary(version: IQAPIVersionObject): boolean {
		if (version.major >= IQAPI_MIN_VERSION_MAJOR) {
			if (version.major === IQAPI_MIN_VERSION_MAJOR && version.minor < IQAPI_MIN_VERSION_MINOR) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * setSearchFormData
	 *
	 * @param conditions - Object
	 */
	setSearchFormData(searchforms: object) {
		this.storage.set(APP_STORAGE.searchforms, searchforms);
	}

	async getSearchFormData() {
		return this.storage.get(APP_STORAGE.searchforms);
	}

	setSortingData(sortingdata: object) {
		this.storage.set(APP_STORAGE.sortingdata, sortingdata);
	}

	async getSortingData() {
		return this.storage.get(APP_STORAGE.sortingdata);
	}

	setSearchWordModeData(searchmodedata: object) {
		localStorage.setItem(APP_STORAGE.searchmodedata, JSON.stringify(searchmodedata));
	}

	getSearchModeData() {
		return JSON.parse(localStorage.getItem(APP_STORAGE.searchmodedata));
	}

	async setSortingDataSelection(sortField, osId) {

		console.log('### setSortingDataSelection ###: ', sortField);
		console.log('### setSortingDataSelection ###: ', osId);

		const sortingData = await this.getSortingData();
		let sortingDataSelection = await this.getSortingDataSelection();
		let position = 0;

		console.log('### sortingDataSelection: ', sortingDataSelection);

		const selectionObj = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Default: 0,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Aktenplanverwaltung: 0,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			VorgangsVerwaltung: 0,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Schriftgutverwaltung: 0,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Adressverwaltung: 0,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Sitzungsverwaltung: 0
		};

		if (!sortingDataSelection) {
			sortingDataSelection = selectionObj;
		}

		if (sortingData.hasOwnProperty(osId)) {
			position = sortingData[osId].findIndex((elem, index) => {
				return elem.value === sortField;
			});
			sortingDataSelection[osId] = position;
		} else {
			position = sortingData.Default.findIndex((elem, index) => {
				return elem.value === sortField;
			});
			sortingDataSelection.Default = position;
		}

		console.log('### selectionObj: ', sortingDataSelection);

		this.storage.set(APP_STORAGE.sortingdataselection, sortingDataSelection);
	}

	async getSortingDataSelection() {
		return this.storage.get(APP_STORAGE.sortingdataselection);
	}

	/**
	 * setUsername
	 *
	 * @param username - String
	 */
	setUsername(username: string) {
		this.storage.set(APP_STORAGE.user.username, username);
	}

	/**
	 * setStructure
	 *
	 * @param structure - String
	 */
	setSelectedStructure(structure: string) {
		this.storage.set(APP_STORAGE.user.selectedstructure, structure);
	}

	getSelectedStructure(): Promise<any> {
		return this.storage.get(APP_STORAGE.user.selectedstructure).then((data) => {
			return data;
		});
	}

	setInitialStorage(key: string, value: any) {
		this.storage.get(key).then((data) => {
			if (!data) {
				this.storage.set(key, value);
			}
		}).catch((e) => {
			console.log('setInitialStorage error: ', e);
		});
	}


	// --- localstorage ---
	getLocalStorageAppStructs(): Array<string> {
		return JSON.parse(localStorage.getItem('appStructs'));
	}

	setLocalStorageAppStructs(appStructsObj: object) {
		const appStructs: Array<string> = [];
		Object.values(appStructsObj).forEach((structure) => {
			appStructs.push(structure);
		});
		localStorage.setItem('appStructs', JSON.stringify(appStructs));
	}

	getToken(): string {
		return localStorage.getItem(APP_STORAGE.tokenname);
	}

	/**
	 * setLocalFavoritesObj
	 *
	 * @param appStructsObj - Object
	 *
	 * Methode wird nach erfolgreichen Login aufgerufen
	 * Es wird geprüft ob das Object für die Favoriten bereits im Storage vorhanden ist
	 * und ggf. neu angelegt.
	 */
	setLocalFavoritesObj(appStructsObj: object) {

		const appStructs: Array<string> = [];
		Object.values(appStructsObj).forEach((structure) => {
			appStructs.push(structure);
		});

		this.getLocalFavorites()
			.catch((error) => {
				try {
					const localFavoriteObj = {};
					appStructs.forEach((structure) => {
						localFavoriteObj[structure] = [];
					});
					this.storage.set(APP_STORAGE.localfavorites, localFavoriteObj);
				} catch (e) {
					console.log('setLocalFavoritesObj error: ', error, e);
				}
			})
			.then((favorites) => {
				if (favorites === null) {
					const localFavoriteObj = {};
					appStructs.forEach((structure) => {
						localFavoriteObj[structure] = [];
					});
					this.storage.set(APP_STORAGE.localfavorites, localFavoriteObj);
				}
			});
	}

	/**
	 * setLocalFavorites
	 */
	setLocalFavorite(osId: string, objID: string) {

		this.getLocalFavorites()
			.catch((e) => {
				console.log('setLocalFavorite error in catch: ', e);
			})
			.then((favorites) => {
				let action: string;

				try {
					let filteredFavorites: Array<any>;
					const found = favorites[osId].find(favorite => favorite === objID);
					if (typeof found === 'undefined') {
						favorites[osId].push(objID);
						action = 'add';
					} else {
						filteredFavorites = favorites[osId].filter((value) => {
							return value !== objID;
						});
						favorites[osId] = filteredFavorites;
						action = 'remove';
					}
					this.storage.set(APP_STORAGE.localfavorites, favorites);
					this.msgService.sendMessage({
						context: 'favorites:edit',
						do: action,
						structure: osId,
						id: objID,
						favoritesObj: favorites
					});
				} catch (e) {
					console.log('setLocalFavorite error in try: ', e);
				}

			});

	}

	/**
	 * getLocalFavorites
	 */
	getLocalFavorites(): Promise<any> {
		return this.storage.get(APP_STORAGE.localfavorites).then((data) => {
			return data;
		});
	}

	/**
	 * getOSFieldsFromCondtions(structure)
	 * Liest die osFields aus den Conditons aus undbereinigt diese um alle Felder mit Unterstrich am Beginn
	 *
	 * @param structure
	 */
	// async getOSFieldsFromCondtions(structure): Promise<any> {
	// 	return this.storage.get(APP_STORAGE.conditions).then((data) => {
	// 		const osFields = data.orgaStructs[structure].osFields;
	// 		const searchedOsFields: Array<string> = [];
	// 		Object.keys(osFields).forEach((osFieldKey) => {
	// 			if (!osFieldKey.startsWith('_')) {
	// 				searchedOsFields.push(osFieldKey);
	// 			}
	// 		});
	// 		searchedOsFields.push('_ObjID');
	// 		return searchedOsFields;
	// 	});
	// }

	getOSFieldsFromCondtionsSync(structure): any {
		const data = JSON.parse(localStorage.getItem(APP_STORAGE.conditions));
		const osFields = data.orgaStructs[structure].osFields;
		const searchedOsFields: Array<string> = [];
		Object.keys(osFields).forEach((osFieldKey) => {
			if (!osFieldKey.startsWith('_')) {
				searchedOsFields.push(osFieldKey);
			}
		});
		searchedOsFields.push('_ObjID');
		searchedOsFields.push('_SYMBFARBE');
		searchedOsFields.push('_FARBE');
		return searchedOsFields;
	}

	async getOsSignumFieldFromCondtions(structure: string): Promise<any> {
		return this.storage.get(APP_STORAGE.conditions).then((data) => {
			return data.orgaStructs[structure].osSignum;
		});
	}

	async getOsFieldDescFromConditions(structure): Promise<any> {
		return this.storage.get(APP_STORAGE.conditions).then((data) => {
			return data.orgaStructs[structure].osFieldDesc;
		});
	}

	async getOsFieldsFromConditions(structure): Promise<any> {
		return this.storage.get(APP_STORAGE.conditions).then((data) => {
			return data.orgaStructs[structure].osFields;
		});
	}

	async getOsStructNameFromConditions(structure): Promise<any> {
		return this.storage.get(APP_STORAGE.conditions).then((data) => {
			return data.orgaStructs[structure].osStructName;
		});
	}

	/**
	 * Gibt den Namen der übergebenen Orgastruktur zurück (osStructName)
	 * (synchron)
	 *
	 * @param structure
	 */
	getOsStructNameFromConditionsSync(structure, singular?) {
		let osStructName: string;
		if (structure) {
			const data = JSON.parse(localStorage.getItem(APP_STORAGE.conditions));
			if (!singular) {
				osStructName = data.orgaStructs[structure].osUnits;
			} else {
				osStructName = data.orgaStructs[structure].osUnit;
			}
			return osStructName;
		}
	}

	/**
	 * Gibt den osSortIndex der übergebenen Orgastruktur zurück (osStructName)
	 * (synchron)
	 *
	 * @param structure
	 */
	getOsSortIndexFromConditionsSync(structure) {
		if (structure) {
			const data = JSON.parse(localStorage.getItem(APP_STORAGE.conditions));
			const osSortIndex = data.orgaStructs[structure].osSortIndex;
			return osSortIndex;
		}

	}

	setSavedSearches(body: SearchObjectsExObj) {
		const savedSearches = localStorage.getItem(APP_STORAGE.savedSearches);
		if (savedSearches) {
			// push inArray
		} else {
			const temp: Array<object> = [];
			temp.push(body);
			localStorage.setItem(APP_STORAGE.savedSearches, JSON.stringify(temp));
		}
	}

	getSavedSearches() {
		return JSON.parse(localStorage.getItem(APP_STORAGE.savedSearches));
	}

	/**
	 * AppConfiguration
	 */
	getAppConfigurationSync(): any {
		try {
			const data = JSON.parse(localStorage.getItem(APP_STORAGE.appConfig));
			return data;
		} catch (e) {
			return null;
		}
	}

	setAppConfigurationSync(configuration: ConfigurationObject): void {
		localStorage.setItem(APP_STORAGE.appConfig, JSON.stringify(configuration));
	}

	/**
	 * Schriftgut
	 */

	async getSchriftgut(userId?: string) {
		if (!userId) {
			return this.storage.get(APP_STORAGE.user.edit);
		} else {
			const edit = await this.storage.get(APP_STORAGE.user.edit);
			return edit[userId];
		}
	}

	async removeFileFromObjectInSchriftgut(objid, filename) {
		const userData = await this.getConditions('user');
		const userId = userData.userId;
		const schriftgut = await this.storage.get(APP_STORAGE.user.edit);

		console.log('## removeFileFromObjectInSchriftgut ##: ', schriftgut[userId]);

		const find = schriftgut[userId][objid].find(o => o.name === filename);
		const findIndex = schriftgut[userId][objid].findIndex(o => o.name === filename);

		console.log('## find ##: ', find);
		console.log('## findIndex ##: ', findIndex);

		schriftgut[userId][objid].splice(findIndex, 1);

		console.log('## schiftgut ##: ', schriftgut);

		return this.storage.set(APP_STORAGE.user.edit, schriftgut);
	}

	async removeObjectFromSchriftgut(objid: string): Promise<any> {
		const userData = await this.getConditions('user');
		const userId = userData.userId;
		const schriftgut = await this.storage.get(APP_STORAGE.user.edit);
		delete schriftgut[userId][objid];
		return this.storage.set(APP_STORAGE.user.edit, schriftgut);
	}

	async setLockedObject(obj: string): Promise<any> {
		const userData = await this.getConditions('user');
		const userId = userData.userId;
		const locked: any = await this.getLockedObjects();

		if (locked.hasOwnProperty(userId)) {
			const index = locked[userId].findIndex(element => {
				if (element.includes(obj)) {
					return true;
				}
			});

			if (index === -1) {
				locked[userId].push(obj);
			}
		} else {
			locked[userId] = [];
			locked[userId].push(obj);
		}

		return this.storage.set(APP_STORAGE.user.locked, locked);
	}

	async removeLockedObject(id: string) {
		console.log('removeLockedObject: ', id);

		const userData = await this.getConditions('user');
		const userId = userData.userId;
		const locked: any = await this.getLockedObjects();

		console.log('locked.hasOwnProperty(userId): ', locked.hasOwnProperty(userId));

		if (locked.hasOwnProperty(userId)) {
			const index = locked[userId].findIndex(element => {
				if (element.includes(id)) {
					return true;
				}
			});

			if (index > -1) {
				console.log('index: ', index);
				console.log('locked: ', JSON.stringify(locked));
				locked[userId].splice(index, 1);
				console.log('locked: ', locked);
				return this.storage.set(APP_STORAGE.user.locked, locked);
			}

		}
	}

	async getLockedObjects() {
		const locked = await this.storage.get(APP_STORAGE.user.locked);
		return locked;
	}

	async storeFileMetaInformations(metadata: any) {
		const userData = await this.getConditions('user');
		const userId = userData.userId;
		const schriftgut: any = await this.getSchriftgut();

		if (schriftgut.hasOwnProperty(userId)) {
			if (schriftgut[userId].hasOwnProperty(metadata.objid)) {

				const docExists = schriftgut[userId][metadata.objid].find(obj => obj.name === metadata.name);
				if (docExists) {
					const docExistsFilter = schriftgut[userId][metadata.objid].filter(obj => obj.name !== metadata.name);
					schriftgut[userId][metadata.objid] = docExistsFilter;
					schriftgut[userId][metadata.objid].push(metadata);
				} else {
					schriftgut[userId][metadata.objid].push(metadata);
				}

			} else {
				schriftgut[userId][metadata.objid] = [metadata];
			}

		} else {
			schriftgut[userId] = {
				[metadata.objid]: [metadata]
			};
		}

		this.storage.set(APP_STORAGE.user.edit, schriftgut);
	}

	getRequiredFields(osFields, orgaStructure?): Array<string> {
		const requiredFields = [];
		let nbr = null;
		Object.keys(osFields).forEach((key) => {

			const obj: any = {};

			if (osFields[key].indexOf('!') > -1) {
				// requiredFields.push(key);
				obj.placeholder = key;
				obj.name = key;
				nbr = osFields[key].match(/\d/g);
				if (nbr !== null) {
					nbr = nbr.join('');
				}
				if (nbr > 100) {
					obj.type = 'textarea';
				} else {
					obj.type = 'text';
				}
				requiredFields.push(obj);
			}

		});

		// aktuell kann nicht geprüft werden, ob ein Pflichtfeld ein Autonummer hat oder nicht.
		// Daher werden hier vorerst manuell die entsprechenden Felder statisch angegeben und
		// wieder entfernt;
		const fieldsToRemove = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Schriftgutverwaltung: ['Schriftstückidentifikation'],
			// eslint-disable-next-line @typescript-eslint/naming-convention
			VorgangsVerwaltung: ['xyz']
		};

		// Array clonen ohne Referenz
		const staticRequiredFields = JSON.parse(JSON.stringify(requiredFields));
		requiredFields.forEach((value, index) => {
			// prüfen ob Orgastruktur in zu entfernden Feldern (fieldsToRemove) existiert
			if (fieldsToRemove.hasOwnProperty(orgaStructure)) {
				// prüfen ob zu entferndendes Feld in requiredFields vorhanden ist
				if (fieldsToRemove[orgaStructure].includes(value.name)) {
					// zu entferndendes Feld aus geclontem Array entfenen
					staticRequiredFields.splice(index, 1);
				}
			}
		});

		// geklontes Array mit Array aus Conditions vergleichen
		// wenn beide die gleiche länge haben, requiredFields zurückgeben.
		// Ansonsten staticRequiredFields zurückgeben.
		if (staticRequiredFields.length === requiredFields.length) {
			return requiredFields;
		} else {
			return staticRequiredFields;
		}

		// return requiredFields;
	}

	async osSuperStructsToOsFields(orgaStructure): Promise<any> {

		const orgaStructs = await this.getConditions('orgaStructs');
		const osSuperStructs = orgaStructs[orgaStructure].osSuperStructs;
		const osFields = [];

		// Vorgangsverwaltung umbenennen zu VorgangsVerwaltung
		const indexOfVorgangsverwaltung = osSuperStructs.indexOf('Vorgangsverwaltung');
		if (indexOfVorgangsverwaltung > -1) {
			osSuperStructs[indexOfVorgangsverwaltung] = 'VorgangsVerwaltung';
		}

		osSuperStructs.forEach((item) => {
			osFields.push(orgaStructs[item].osSignum);
		});

		return osFields;
	}

	async getSelectedDatastoreIds(selected: Array<string>): Promise<any> {
		const dataStores = await this.getConditions('dataStores');
		const dataStoreIds = [];

		// -- Prüfung welcher Key für die DataStore-ID verwendet wird. --
		//
		// Ursprünglich (in vorheriger App) wurde 'dataStoreID' als Schlüssel verwendet.
		// In der neuen IQAPI wurde der Schlüssel umbenannt zu 'dataStoreId', was in der
		// vorherigen App zu einem Fehler bei der Suche führte. Daher wurde die Umbenennung
		// wieder rückgängig gemacht.
		// Um in Zukunft Fehler bei einer erneuten Umbennung zu vermeiden (wenn kein Kunde mehr die alte App verwendet)
		// wird hier geprüft, welcher Schlüssel verwendet wird.
		let dataStoreIdKey = '';
		if (dataStores.length > 0) {
			if (dataStores[0].hasOwnProperty('dataStoreId')) {
				dataStoreIdKey = 'dataStoreId';
			} else if (dataStores[0].hasOwnProperty('dataStoreID')) {
				dataStoreIdKey = 'dataStoreID';
			}
		}

		selected.forEach((item, index) => {
			const test = dataStores.filter(o => o.name === item);
			dataStoreIds.push(test[0][dataStoreIdKey]);
		});

		return dataStoreIds;

	}

	setRecivedIntend(intend: string) {
		localStorage.setItem(APP_STORAGE.recivedintend, intend);
	}

	getRecivedIntend() {
		return localStorage.getItem('recivedintend');
	}

	removeRecivedIntend() {
		return localStorage.removeItem('recivedintend');
	}

	setRecivedIntendFromURLScheme(intend: string) {
		localStorage.setItem(APP_STORAGE.recivedintendfromcustomurlscheme, intend);
	}

	getRecivedIntendFromURLScheme() {
		return localStorage.getItem('recivedintendfromcustomurlscheme');
	}

	removeRecivedIntendFromURLScheme() {
		return localStorage.removeItem('recivedintendfromcustomurlscheme');
	}


}
