import {Directive, ElementRef, Renderer2} from '@angular/core';
import {Keyboard} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';

@Directive({
	selector: '[appToggleFooter]'
})
export class ToggleFooterDirective {

	constructor(
		public el?: ElementRef,
		private renderer?: Renderer2) {

		if (Capacitor.getPlatform() !== 'web') {
			Keyboard.addListener('keyboardWillShow', (info) => {
				this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
			});

			Keyboard.addListener('keyboardWillHide', () => {
				this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
			});
		}
	}
}

@Directive({
	selector: '[appToggleSidebar]'
})
export class ToggleSidebarDirective {

	constructor(
		public el?: ElementRef,
		private renderer?: Renderer2) {

		if (Capacitor.getPlatform() !== 'web') {
			Keyboard.addListener('keyboardWillShow', (info) => {
				this.renderer.addClass(this.el.nativeElement, 'keyboard-show');
			});

			Keyboard.addListener('keyboardWillHide', () => {
				this.renderer.removeClass(this.el.nativeElement, 'keyboard-show');
			});
		}
	}
}

@Directive({
	selector: '[appToggleSearchFab]'
})
export class ToggleSearchFabDirective {

	constructor(
		public el?: ElementRef,
		private renderer?: Renderer2) {

		if (Capacitor.getPlatform() !== 'web') {
			Keyboard.addListener('keyboardWillShow', (info) => {
				this.renderer.removeClass(this.el.nativeElement, 'hidden');
			});

			Keyboard.addListener('keyboardWillHide', () => {
				this.renderer.addClass(this.el.nativeElement, 'hidden');
			});
		}
	}
}


