import {Injectable} from '@angular/core';
import {
	DeleteFileOptions,
	Directory,
	Encoding,
	Filesystem,
	GetUriOptions,
	MkdirOptions,
	ReaddirOptions,
	ReadFileOptions,
	RmdirOptions,
	StatOptions,
	WriteFileOptions
} from '@capacitor/filesystem';
import {CopyOptions} from '@capacitor/filesystem/dist/esm/definitions';


const APP_DIRECTORY = Directory.Documents;

@Injectable({
	providedIn: 'root'
})
export class DateiSystemService {

	constructor() {
	}

	/**
	 * pruefeAppOrder()
	 *
	 * Prüft ob ein Ordner existiert und gibt das Ergebnis
	 * als Boolschen Wert zurück
	 *
	 * @param ordnername
	 */
	async pruefeAppOrder(ordnername: string): Promise<any> {
		const options: ReaddirOptions = {
			path: ordnername,
			directory: APP_DIRECTORY
		};

		return Filesystem.readdir(options)
			.then((data) => {
				// console.log('Filesystem readdir: ', data, APP_DIRECTORY);
				return true;
			})
			.catch(() => false);
	}

	/**
	 * erstelleAppOrdner()
	 *
	 * Erstellt einen Ordner im angegebenen Verzeichniss und
	 * gibt das Ergebnis als Boolschen Wert zurück
	 *
	 * @param ordnername
	 */
	async erstelleAppOrdner(ordnername: string): Promise<any> {
		const option: MkdirOptions = {
			path: ordnername,
			directory: APP_DIRECTORY,
			recursive: false
		};
		return Filesystem.mkdir(option)
			.then(() => true)
			.catch(() => false);
	}

	async schreibeDatei(ordnername: string, dateiname: string, content: string): Promise<any> {
		const options: WriteFileOptions = {
			directory: APP_DIRECTORY,
			encoding: Encoding.UTF8,
			data: 'data:image/jpeg;base64,' + content,
			path: ordnername + '/' + dateiname
		};

		return Filesystem.writeFile(options)
			.then((data) => {
				// console.log('writeFile: ', data);
				return true;
			})
			.catch((error) => {
				// console.log('writeFile error: ', error);
				return false;
			});
	}

	async leseDatei(pfad: string, dateiname: string): Promise<any> {
		const options: ReadFileOptions = {
			directory: APP_DIRECTORY,
			path: pfad + '/' + dateiname,
			encoding: Encoding.UTF8
		};

		return Filesystem.readFile(options)
			.then((data) => {
				// console.log('leseDatei: ', data);
				return data;
			})
			.catch((error) => {
				// console.log('leseDatei error: ', error);
				return false;
			});
	}

	async loescheOrdner(pfad: string): Promise<any> {
		const option: RmdirOptions = {
			path: pfad,
			directory: APP_DIRECTORY,
			recursive: true
		};

		return Filesystem.rmdir(option)
			.then((data) => {
				return true;
			})
			.catch((error) => {
				return false;
			});
	}

	async loescheDatei(pfad: string): Promise<any> {
		const option: DeleteFileOptions = {
			path: pfad,
			directory: APP_DIRECTORY
		};

		return Filesystem.deleteFile(option)
			.then((data) => {
				return true;
			})
			.catch((error) => {
				return false;
			});
	}

	// https://file-examples.com/
	// https://www.youtube.com/watch?v=KdIgThOqqhU

	async downloadDatei() {
		// return Filesystem.
	}

	async schreibeKonfiguration(ordnername: string, dateiname: string, content: string): Promise<any> {
		const options: WriteFileOptions = {
			directory: APP_DIRECTORY,
			encoding: Encoding.UTF8,
			data: content,
			path: ordnername + '/' + dateiname
		};

		return Filesystem.writeFile(options)
			.then((data) => {
				console.log('writeFile: ', data);
				return true;
			})
			.catch((error) => {
				console.log('writeFile error: ', error);
				return false;
			});
	}

	async getURI(filePath): Promise<any> {
		const options: GetUriOptions = {
			directory: APP_DIRECTORY,
			path: filePath
		}

		return Filesystem.getUri(options)
			.then((data) => {
				console.log('getURI: ', data);
				return data;
			})
			.catch((error) => {
				console.log('getURI: ', error);
				return error;
			});
	}

	async getFileInfo(filePath) {
		const options: StatOptions = {
			path: filePath,
			directory: APP_DIRECTORY
		};
		return Filesystem.stat(options);
	}

	async kopiereDateiNach(fromPath, targetFolder, filename) {
		const options: CopyOptions = {
			from: fromPath,
			to: targetFolder + filename,
			toDirectory: APP_DIRECTORY
		};
		return Filesystem.copy(options)
			.then((data) => true)
			.catch((error) => false);
	}

	async leseDateienInOrdner(ordnername: string): Promise<any> {
		const options: ReaddirOptions = {
			path: ordnername,
			directory: APP_DIRECTORY
		};

		return Filesystem.readdir(options)
			.then((data) => data)
			.catch((e) => e);
	}

	async readIQOFile(url) {
		return Filesystem.readFile({path: url});
	}

	async schreibeDateiHash(content: string): Promise<any> {

		const options: WriteFileOptions = {
			directory: Directory.External,
			encoding: Encoding.UTF8,
			data: content,
			path: 'regisafe.hash.txt'
		};

		return Filesystem.writeFile(options)
			.then((data) => {
				return true;
			})
			.catch((error) => {
				return false;
			});
	}

	async leseDateiHash(): Promise<any> {
		const options: ReadFileOptions = {
			directory: Directory.External,
			path: 'regisafe.hash.txt',
			encoding: Encoding.UTF8
		};

		return Filesystem.readFile(options)
			.then((data) => {
				return data;
			})
			.catch((error) => {
				return false;
			});
	}

}
