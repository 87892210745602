// Alerts
export const ALERT_TXT = {
	header: {
		str00010: 'Achtung',
		str00020: 'Hinweis',
		str00030: 'Fehlende Berechtigung',
		str00040: 'Datei hinzufügen',
		str00050: 'Ergebnisse sortieren',
		str00060: 'Hinzufügen',
		str00070: 'Schriftstück',
		str00080: 'Datei',
		str00090: 'Offline Modus aktivieren',
		str00100: 'Eingabe',
		str00110: 'Datei(en) hinzufügen',
	},
	subHeader: {
		str00010: 'Dieses Schriftstück ist noch zur Bearbeitung gesperrt.',
		str00020: 'Sie haben diese Datei bearbeitet. Möchten sie Ihre Änderungen jetzt hochladen?',
		str00030: 'Sie haben noch Objekte in Bearbeitung.',
		str00040: 'Die Daten wurden gelesen und die Konfiguration muss aktualisiert werden. Danach können Sie sich mit Ihren Zugangsdaten anmelden.',
		str00050: 'Möchten Sie Ihre Zugangsdaten speichern, damit Sie sich beim nächsten mal mit Ihrem Fingerabdruck bzw. Ihrer Face-Id einloggen können?',
		str00060: 'Bitte wählen Sie aus, von wo sie eine Datei hinzufügen möchten.',
		str00070: 'Möchten Sie die Datei "$1" zum Schriftstück hinzufügen?',
		str00080: 'Es wurden keine Änderungen gefunden. Möchten Sie dieses Schriftstück aus der Bearbeitung entfernen?',
		str00090: 'Das Schriftstück wurde zwischenzeitlich durch einen anderen Benutzer bearbeitet. Möchten Sie Ihre Änderungen als Kopie anlegen oder alles überschreiben?',
		str00100: 'Das Dokument auf dem Server unterscheidet sich von Ihrer Version. Möchten Sie das Dokument überschreiben oder eine Kopie anlegen?',
		str00110: 'Diese Aktion entfernt sämtliche Konfigurationen und Dateien der App. Möchten Sie diese Aktion wirklich ausführen? Die App wird im Anschluß neu geladen.',
		str00120: 'Möchten Sie die ausgewählten Dateien ($1) zum Schriftstück hinzufügen?',
		str00130: 'Möchten Sie sich wirklich abmelden?',
		str00140: 'Sie haben noch Objekte in Bearbeitung. Möchten Sie sich wirklich abmelden?',
		str00150: 'Möchten Sie die App wirklich verlassen und sich abmelden?',
	},
	message: {
		str00010: 'aus Schriftstück',
		str00020: 'Öffnen Sie bitte die Einstellungen auf Ihrem Gerät und erlauben Sie den Zugriff auf die Kamera.',
		str00030: 'Möchten Sie zukünftig den Offline Modus verwenden? Sie können diese Option auch später in Ihren Einstellungen setzen.',
		str00040: 'Möchten Sie Ihre Offline Login wirklich zurücksetzen? Die hinterlegten Daten werden gelöscht.',
		str00050: 'Sie werden zum Login weitergeleitet. Bitte melden Sie sich erneut an und bestätigen Sie dann Ihren Offline Login.',
		str00060: 'Möchten Sie Ihre Daten für den biometrischen Login wirklich zurücksetzen? Die hinterlegten Daten werden gelöscht.',
		str00070: 'Sie werden zum Login weitergeleitet. Bitte melden Sie sich erneut an und bestätigen Sie dann Ihren biometrischen Login.',
		str00080: '',
		str00090: '',
		str00100: ''
	},
	buttons: {
		str00010: 'Abbrechen',
		str00020: 'Schriftstück entsperren',
		str00030: 'Seite verlassen',
		str00040: 'Einstellungen öffnen',
		str00050: 'Hochladen',
		str00060: 'Zur Bearbeitungsliste',
		str00070: 'Jetzt aktualisieren',
		str00080: 'Ja',
		str00090: 'Nein',
		str00100: 'Galerie',
		str00110: 'Dateien',
		str00120: 'Kamera',
		str00130: 'Ok',
		str00140: 'Entfernen',
		str00150: 'Kopie anlegen',
		str00160: 'Überschreiben',
		str00170: 'Hinzufügen',
		str00180: 'Anzeigen',
		str00190: 'Änderungen Hochladen',
		str00200: 'Aus Bearbeitung entfernen',
		str00210: 'Zur Bearbeitung öffnen',
		str00220: 'Später',
		str00230: 'Ignorieren',
		str00240: 'Deaktivieren',
		str00250: 'Aktivieren',
		str00260: 'Zurücksetzen',
		str00270: 'Abmelden',
	}
};
// Toasts
export const TOAST_TXT = {
	message: {
		str00010: 'Die Datei ist vom falschen Typ. Sie können nur *.IQO Dateien nach regisafe teilen.',
		str00011: 'Datei erfolgreich in regisafe Client hochgeladen.',
		str00012: 'Dateien erfolgreich in regisafe Client hochgeladen.',
		str00020: 'Die Datei konnte nicht gelesen werden. Laden Sie die Datei bitte herunter und versuchen Sie diese dann von dort aus zu öffnen.',
		str00030: 'Bitte prüfen Sie die Konfiguration!',
		str00040: 'Ihre Zugangsdaten konnten nicht gespeichert werden',
		str00050: 'Bitte prüfen Sie Ihre Eingabe. Ihre Eingabe stimmt nicht mit dem hinterlegten Passwort überein.',
		str00060: 'Sie konnten nicht authentifiziert werden.',
		str00070: 'Zugangsdaten konnten nicht gelesen werden..',
		str00080: 'Es wurden keine Ergebnisse gefunden.',
		str00090: 'Dateien erfolgreich in regisafe Client hochgeladen.',
	}
};

// Loader
export const LOADER_TXT = {
	message: {
		str00010: 'Sie werden abgemeldet',
	}
};

// Views

// Native
export const NATIVES_TXT = {
	biometric: {
		reason: 'regisafe Login',
		title: 'regisafe',
		subtitle: 'Biometrischer Login',
		description: 'Bitte warten Sie, bis Ihre Daten erfasst sind'
	}
};

