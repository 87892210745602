import {Directive, ElementRef, Renderer2} from '@angular/core';
import {MessagesService} from '../provider/messages/messages.service';
import {Subscription} from 'rxjs';

@Directive({
	selector: '[appDateienInBearbeitung]'
})
export class DateienInBearbeitungDirective {

	subscription: Subscription;

	constructor(el?: ElementRef, private renderer?: Renderer2, private msgService?: MessagesService) {
		const dateienInBearbeitung = false;
		if (dateienInBearbeitung) {
			this.renderer.addClass(el.nativeElement, 'dateien-in-bearbeitung');
		} else {
			this.renderer.removeClass(el.nativeElement, 'dateien-in-bearbeitung');
		}
		this.subscription = this.msgService.getMessages().subscribe((msg: any) => {
			if (msg.message.filesInProgress) {
				this.renderer.addClass(el.nativeElement, 'dateien-in-bearbeitung');
			} else {
				this.renderer.removeClass(el.nativeElement, 'dateien-in-bearbeitung');
			}
		});
	}

}
