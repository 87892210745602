import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {APP_ROUTES, APP_STORAGE} from '../../../environments/environment';
import {StorageService} from '../storage/storage.service';
import {Router} from '@angular/router';
import {MessagesService} from '../messages/messages.service';
import {ALERT_TXT, TOAST_TXT} from '../../strings/language';
import {Md5} from 'ts-md5';
import {ErrorHandlingService} from '../error-handling/error-handling.service';
import * as CryptoJS from 'crypto-js';
import {DateiSystemService} from '../datei-system/datei-system.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	loader: any;
	resultCacheData = {
		results: [],
		totalResults: 0,
		outParams: {
			searchLevel1: 0
		}
	};

	modal: HTMLIonModalElement;

	mimeTypes = {
		'aac': 'audio/aac',
		'abw': 'application/x-abiword',
		'arc': 'application/x-freearc',
		'avif': 'image/avif',
		'avi': 'video/x-msvideo',
		'azw': 'application/vnd.amazon.ebook',
		'bin': 'application/octet-stream',
		'bmp': 'image/bmp',
		'bz': 'application/x-bzip',
		'bz2': 'application/x-bzip2',
		'cda': 'application/x-cdf',
		'csh': 'application/x-csh',
		'css': 'text/css',
		'csv': 'text/csv',
		'doc': 'application/msword',
		'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'eot': 'application/vnd.ms-fontobject',
		'epub': 'application/epub+zip',
		'gz': 'application/gzip',
		'gif': 'image/gif',
		'htm': 'text/html',
		'html': 'text/html',
		'ico': 'image/vnd.microsoft.icon',
		'ics': 'text/calendar',
		'jar': 'application/java-archive',
		'jpeg': 'image/jpeg',
		'jpg': 'image/jpeg',
		'js': 'text/javascript',
		'json': 'application/json',
		'jsonld': 'application/ld+json',
		'mid': 'audio/midi',
		'midi': 'audio/x-midi',
		'mjs': 'text/javascript',
		'mp3': 'audio/mpeg',
		'mp4': 'video/mp4',
		'mpeg': 'video/mpeg',
		'mpkg': 'application/vnd.apple.installer+xml',
		'odp': 'application/vnd.oasis.opendocument.presentation',
		'ods': 'application/vnd.oasis.opendocument.spreadsheet',
		'odt': 'application/vnd.oasis.opendocument.text',
		'oga': 'audio/ogg',
		'ogv': 'video/ogg',
		'ogx': 'application/ogg',
		'opus': 'audio/opus',
		'otf': 'font/otf',
		'png': 'image/png',
		'pdf': 'application/pdf',
		'php': 'application/x-httpd-php',
		'ppt': 'application/vnd.ms-powerpoint',
		'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'rar': 'application/vnd.rar',
		'rtf': 'application/rtf',
		'sh': 'application/x-sh',
		'svg': 'image/svg+xml',
		'tar': 'application/x-tar',
		'tif ': 'image/tiff',
		'tiff': 'image/tiff',
		'ts': 'video/mp2t',
		'ttf': 'font/ttf',
		'txt': 'text/plain',
		'vsd': 'application/vnd.visio',
		'wav': 'audio/wav',
		'weba': 'audio/webm',
		'webm': 'video/webm',
		'webp': 'image/webp',
		'woff': 'font/woff',
		'woff2': 'font/woff2',
		'xhtml': 'application/xhtml+xml',
		'xls': 'application/vnd.ms-excel',
		'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'xml': 'application/xml',
		'xul': 'application/vnd.mozilla.xul+xml',
		'zip': 'application/zip',
		'3gp': 'video/3gpp; audio/3gpp',
		'3g2': 'video/3gpp2; audio/3gpp2',
		'7z': 'application/x-7z-compressed',
		'iqo': 'text/plain',
	};

	existingOrgastructIcons = [
		'Adressverwaltung',
		'Aktenplanverwaltung',
		'Grundstücksverwaltung',
		'Schriftgutverwaltung',
		'Sitzungsverwaltung',
		'Verteilerverwaltung',
		'Verwaltung',
		'VorgangsVerwaltung'
	];

	constructor(public toastCtrl: ToastController,
				public loadingCtrl: LoadingController,
				public modalCtrl: ModalController,
				public alertCtrl: AlertController,
				public platform: Platform,
				private storageService: StorageService,
				private router: Router,
				private errorHandling: ErrorHandlingService,
				private dateiService: DateiSystemService,
				private msgService: MessagesService) {
	}

	async presentDefaultToast(msg: string, dur: number = 2500, pos: any = 'middle') {

		const toast = await this.toastCtrl.create({
			message: msg,
			duration: dur,
			position: pos
		});

		toast.present();
	}

	/**
	 *
	 * @param needle
	 * @param type (text, icon)
	 */
	findAndTranslateColor(needle: string, type: string): any {

		const colorObject = {
			'Blau': '0,60,235',
			'Blau fett': '0,60,235',
			'Blaugrün': '0,128,128',
			'Blaugrün fett': '0,128,128',
			'Braun': '128,63,0',
			'Braun fett': '128,63,0',
			'Chamois': '255,235,140',
			'Chamois fett': '255,235,140',
			'Dunkelblau': '0,0,85',
			'Dunkelblau fett': '0,0,85',
			'Dunkelrot': '128,0,0',
			'Dunkelrot fett': '128,0,0',
			'Dunkelgrau': '63,63,63',
			'Dunkelgrau fett': '63,63,63',
			'Gelb': '255,255,0',
			'Gelb fett': '255,255,0',
			'Grau': '128,128,128',
			'Grau fett': '128,128,128',
			'Grün': '0,128,0',
			'Grün fett': '0,128,0',
			'Hellgrün': '0,255,0',
			'Hellgrün fett': '0,255,0',
			'Hellgrau': '192,192,192',
			'Hellgrau fett': '192,192,192',
			'Lila': '255,0,255',
			'Lila fett': '255,0,255',
			'Olivgrün': '128,128,0',
			'Olivgrün fett': '128,128,0',
			'Orange': '255,118,0',
			'Orange fett': '255,118,0',
			'Rosa': '255,128,128',
			'Rosa fett': '255,128,128',
			'Rot': '255,0,0',
			'Rot fett': '255,0,0',
			'Schwarz': '0,0,0',
			'Schwarz fett': '0,0,0',
			'Silber': '192,192,192',
			'Silber fett': '192,192,192',
			'Türkis': '0,255,255',
			'Türkis fett': '0,255,255',
			'Violett': '128,0,128',
			'Violett fett': '128,0,128',
			'Weiß': '255,255,255',
			'Weiß fett': '255,255,255'
		};

		const styleObj = {
			'color': 'auto',
			'border-color': 'transparent',
			'font-weight': 'normal'
		};

		for (const property in colorObject) {

			if (colorObject.hasOwnProperty(needle)) {
				if (!needle.includes('fett')) {
					if (type === 'text') {
						styleObj['color'] = 'rgb(' + colorObject[needle] + ')';
					}
					if (type === 'icon') {
						styleObj['border-color'] = 'rgba(' + colorObject[needle] + ', 1)';
						styleObj['font-weight'] = '900';
					}
					return styleObj;
				} else {
					if (type === 'text') {
						styleObj['color'] = 'rgb(' + colorObject[needle] + ')';
						styleObj['font-weight'] = 'bold';
					}
					if (type === 'icon') {
						styleObj['border-color'] = 'rgba(' + colorObject[needle] + ', 1)';
						styleObj['font-weight'] = '900';
					}

					return styleObj;
				}
			} else {
				if (type === 'icon') {
					styleObj['font-weight'] = '900';
				}
				return styleObj;
			}
		}
	}

	isNativeRun(): boolean {
		let isNative = false;
		isNative = this.platform.is('ios') || this.platform.is('android');
		return isNative;
	}

	/**
	 * replaceString()
	 *
	 * @param str
	 * @param search
	 * @param replacement
	 *
	 * Replaces the first occurrence of search string with the specified new string.
	 * If the "all" option is set to true, all occurrences of the search string are replaced
	 */
	replaceString(str: string, search: string, replacement: string, all?: boolean) {
		let flag: string;

		if (all) {
			flag = 'g';
		} else {
			flag = 'i';
		}

		const regexp = new RegExp(search, flag);
		const convertedString = str.replace(regexp, replacement);
		return convertedString;
	}

	objIdAsRouteParam(objId: string) {
		console.log('objIdAsRouteParam -->: ', this.replaceString(objId, ':', '--', true));
		return this.replaceString(objId, ':', '--', true);
	}

	objIdAsIQAPIParam(objId: string) {
		return this.replaceString(objId, '--', ':', true);
	}

	async presentLoader(msg: string = '') {

		try {
			this.loader.dimiss();
		} catch (e) {
			console.log('presentLoader error dismiss', e);
		}

		this.loader = await this.loadingCtrl.create({
			message: msg,
			duration: 10000
		});
		await this.loader.present();
	}

	async dismissLoader() {
		let topLoader = await this.loadingCtrl.getTop();
		while (topLoader) {
			if (!(await topLoader.dismiss())) {
				// error
			}
			topLoader = await this.loadingCtrl.getTop();
		}
	}

	niceBytes(x: any): string {

		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let l = 0;
		let n = parseFloat(x) || 0;

		while (n >= 1024 && ++l) {
			n = n / 1024;
		}

		// include a decimal point and a tenths-place digit if presenting
		// less than ten of KB or greater units
		return (n.toFixed(n < 10 && l > 0 ? 2 : 0) + ' ' + units[l]);
	}

	checkIsNative(): boolean {
		const native = !(this.platform.is('desktop') || this.platform.is('mobileweb'));
		return native;
	}

	/**
	 * formatDate()
	 *
	 * formatiert das übergebene Datum
	 *
	 * @param date
	 */
	public formatDate(date, regisafeDate: boolean = false, format?: string) {

		if (typeof date === 'string' && !regisafeDate) {
			date = parseInt(date, 10);
		}

		const d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		const year = d.getFullYear();
		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}
		if (!format) {
			return [year, month, day].join('-');
		} else {
			if (format === 'DD.MM.YYYY') {
				return [day, month, year].join('.');
			}
		}
	}

	randomChars(length: number): string {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let str = '';
		for (let i = 0; i < length; i++) {
			str += chars.charAt(Math.floor((Math.random() * chars.length)));
		}
		return str;
	}

	public base64toBlob(b64Data, contentType) {
		contentType = contentType || '';
		const sliceSize = 512;
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, {
			type: contentType
		});
		return blob;
	}

	async getCurrentDate(): Promise<string> {

		const currentdate = new Date();
		const datetime = currentdate.getDate() + '-'
			+ (currentdate.getMonth() + 1) + '-'
			+ currentdate.getFullYear() + '_'
			+ currentdate.getHours() + '-'
			+ currentdate.getMinutes() + '-'
			+ currentdate.getSeconds();

		return datetime;
	}

	async checkFilesInProcess(showAlert: boolean = true): Promise<any> {
		const user = await this.storageService.getConditions('user');
		const filesInProcess = await this.storageService.getSchriftgut();
		try {
			if (filesInProcess) {
				if (filesInProcess.hasOwnProperty(user.userId)) {

					if (showAlert) {
						if (Object.keys(filesInProcess[user.userId]).length > 0) {

							// Menüpunkt "In Bearbeitung" markieren, wenn Objekte in Bearbeitung sind
							this.msgService.sendMessage({filesInProgress: true});

							const alert = await this.alertCtrl.create({
								header: ALERT_TXT.header.str00010,
								subHeader: ALERT_TXT.subHeader.str00030,
								buttons: [{
									text: ALERT_TXT.buttons.str00060,
									role: 'objects-in-process',
									handler: () => {
										this.router.navigate([APP_ROUTES.inbearbeitung]);
									}
								}, {
									text: ALERT_TXT.buttons.str00230,
									role: 'cancel'
								}],
								backdropDismiss: false
							});

							await alert.present();
							return alert.onDidDismiss();
						}
					} else {
						return Object.keys(filesInProcess[user.userId]).length > 0;
					}
				}
			}
		} catch (e) {
			console.log('checkFilesInProcess error: ', e);
		}
	}

	async askForLogout(headerTxt, subheaderTxt, btnTxt) {
		const alert = await this.alertCtrl.create({
			header: headerTxt,
			subHeader: subheaderTxt,
			buttons: [{
				text: 'Abmelden',
				role: 'logout'
			}, {
				text: btnTxt,
				role: 'cancel'
			}]
		});

		await alert.present();
		return alert.onDidDismiss();
	}

	/**
	 * languageStringReplacer()
	 *
	 * Sucht in einem String nach den Vorkommen von 'needle' und ersetzt dieses durch den jeweils im Array
	 * angegeben String. Die Anzahl der Ersetzungen ist Abhängig von der Länge des Arrays 'replacer' und der
	 * Vorkommen von 'needle'.
	 *
	 * @param input
	 * @param replacer
	 * @param needle
	 */
	languageStringReplacer(input: string, replacer: Array<string>, needle: string): string {
		let output;
		for (const element of replacer) {
			output = input.replace(needle, element);
		}

		return output;
	}

	/**
	 * setTitle()
	 *
	 * schneidet den übergebene Titel, je nach Auflösung nach eine vorgegeben Anzahl zeichen ab
	 * und gibt das Ergebnis als String zurück.
	 *
	 * @param title
	 */
	setTitle(title: string): string {
		let max = 240;
		let cutted;
		const stringEnd = '...';
		const innerW = window.innerWidth;
		if (innerW < 768 && innerW > 500) {
			max = 120;
			if (title.length > max) {
				cutted = title.substr(0, max) + stringEnd;
			} else {
				cutted = title;
			}
			return cutted;
		} else if (innerW <= 500) {
			max = 60;
			if (title.length > max) {
				cutted = title.substr(0, max) + stringEnd;
			} else {
				cutted = title;
			}
			return cutted;
		} else if (title.length > max) {
			return title.substr(0, max) + stringEnd;
		} else {
			return title;
		}
	}


	/**
	 * Hash
	 */

	async storePasswordForOfflineLogin(data) {
		const makeHash = await this.makeHash(data.password, true);
		console.log('storePasswordForOfflineLogin makeHash: ', makeHash);
		if (makeHash) {
			await this.storageService.setItem(APP_STORAGE.user.offlinelogin, 1);
		} else {
			this.errorHandling.errorMessage(TOAST_TXT.message.str00040, 'toast', true);
		}
	}

	async makeHash(input: string, store: boolean): Promise<boolean> {
		const reversed = this.reverseString(input.trim());
		const md5Hash = Md5.hashStr(reversed);
		const hash = CryptoJS.SHA3(md5Hash, {outputLength: 512});
		if (store) {
			const stored = await this.storeHash(hash.toString());
			return stored;
		} else {
			return hash.toString();
		}
	}

	reverseString(str: string): string {
		const splitted = str.split('');
		const reversedArray = splitted.reverse();
		return reversedArray.join('');
	}

	async storeHash(hash: string): Promise<any> {
		return this.dateiService.schreibeDateiHash(hash);
	}

	async makeHashFromPassword(input: string): Promise<string> {
		const hash = await this.makeHash(input, false);
		return hash.toString();
	}

	getExistingOrgastructIconOrDefault(orgaStruct): string {
		if (this.existingOrgastructIcons.includes(orgaStruct)) {
			return orgaStruct;
		} else {
			return 'Default';
		}
	}

}
